import { TopicStatsVotesType } from './topicStats';

export interface TopicType {
  title: string;
  description: string;
  options: string[];
  createdAt: string;
  startAt: string;
  endAt: string;
  updatedAt: string;
  minVoteStakingAmount: string;
  sealed: boolean;
  voided: boolean;
  voted: { [address: string]: number };
  votesCountActual: string[];
}

export enum TopicState {
  UPCOMING = 'upcoming',
  ACTIVE = 'active',
  ENDED = 'ended',
}

export interface SerializedTopicType extends TopicType {
  id: number;
  state: TopicState;
  userVotedOptionIndex?: number;
  hasFetchedVotes: boolean;
  leadingOption?: number;
  myRank?: number;
  canCurrentUserVote?: boolean;
  isVoting: boolean;
  // Attributes from API
  distribution: number[];
  totalVotes: number;
  uniqueWallets: number;
  votes?: TopicStatsVotesType[];
  shouldWeightVotingPower: boolean;
  slug: string | undefined;
}
