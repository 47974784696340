import { FC, useContext } from 'react';
import Layout from 'components/Layout';
import ListItem from 'components/ListItem';
import TopicsContext from 'context/topics';

const IndexPage: FC = () => {
  const { topics } = useContext(TopicsContext);

  const sortedTopics = [...topics].sort((a, b) => +b.updatedAt - +a.updatedAt);
  return (
    <Layout>
      {!!sortedTopics.length &&
        sortedTopics.map((topic) =>
          !topic.voided ? (
            <ListItem
              key={topic.id}
              topic={topic}
              mt="20px"
              mx={{ base: '10px', md: 0 }}
              _first={{ mt: '0' }}
            />
          ) : null
        )}
    </Layout>
  );
};

export default IndexPage;
