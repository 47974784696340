import { TopicInfo } from 'types/topicStats';

export const fetchAllTopicsStats = (): Promise<TopicInfo[]> => {
  return new Promise<TopicInfo[]>((resolve, reject) => {
    fetch(`${process.env.REACT_APP_API_URL}/statistics`, {
      method: 'GET',
    })
      .then((result) => {
        result.json().then((data) => {
          resolve(data);
        });
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
