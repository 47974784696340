import {
  Box,
  Heading,
  ListItem,
  OrderedList,
  Text,
  UnorderedList,
} from '@chakra-ui/react';
import { FC } from 'react';

const Rules: FC<{ isAppliedSquareRootMethod: boolean }> = ({
  isAppliedSquareRootMethod,
}) => {
  return (
    <>
      <Heading as="h3" variant="h3" mb={{ base: '20px', md: '30px' }}>
        Rules & Calculation
      </Heading>
      {isAppliedSquareRootMethod ? (
        <RulesWithSquareRoot />
      ) : (
        <RulesWithoutSquareRoot />
      )}
    </>
  );
};

const RulesWithSquareRoot = () => (
  <>
    <OrderedList
      fontSize="16px"
      lineHeight={{ base: '20px', md: '26px' }}
      marginInlineStart="1.5em"
      mb="20px"
    >
      <ListItem>Member is able to vote with one option once.</ListItem>
      <ListItem>
        Member is not able to vote again or change their vote if they already
        voted.
      </ListItem>
      <ListItem>
        Once the poll ends, each option will be weighed in proportion to the
        collective voted $BLT in said poll, with the square root method applied.
      </ListItem>
    </OrderedList>

    <Box
      pl={{ base: '0', md: '24px' }}
      lineHeight={{ base: '20px', md: '26px' }}
    >
      <Text fontWeight="600">For example:</Text>
      <UnorderedList marginInlineStart="1.5em">
        <ListItem>
          There are 3 options (X, Y, Z) and 4 users (A, B, C, D) voted:
        </ListItem>

        <UnorderedList marginInlineStart="2em">
          <ListItem>User A: 100 BLT in-stake, vote for option X</ListItem>
          <ListItem>User B: 3,600 BLT in-stake, vote for option X</ListItem>
          <ListItem>User C: 10,000 BLT in-stake, vote for option Y</ListItem>
          <ListItem>User D: 1,000,000 BLT in-stake, vote for option Z</ListItem>
        </UnorderedList>

        <ListItem>Square root:</ListItem>

        <UnorderedList marginInlineStart="2em">
          <ListItem>square root (100) = 10</ListItem>
          <ListItem>square root (3,600) = 60</ListItem>
          <ListItem>square root (10,000) = 100</ListItem>
          <ListItem>square root (1,000,000) = 1,000</ListItem>
        </UnorderedList>
      </UnorderedList>

      <Text fontWeight="600" lineHeight="26px" mt="20px">
        Result - approval rating:
      </Text>
      <UnorderedList marginInlineStart="1.5em" lineHeight="26px">
        <ListItem>Option X: 70 / 1,170 = 5.98%</ListItem>
        <ListItem>Option Y: 100 / 1,170 = 8.55%</ListItem>
        <ListItem>Option Z: 1,000 / 1,170 = 85.47%</ListItem>
      </UnorderedList>
      <Text fontWeight="700" marginInlineStart="0.2em" lineHeight="26px">
        → Option Z will be the final decision.
      </Text>
    </Box>
  </>
);

const RulesWithoutSquareRoot = () => (
  <>
    <OrderedList
      fontSize="16px"
      lineHeight={{ base: '20px', md: '26px' }}
      marginInlineStart="1.5em"
      mb="20px"
    >
      <ListItem>Member is able to vote with one option once.</ListItem>
      <ListItem>
        Member is not able to vote again or change their vote if they already
        voted.
      </ListItem>
      <ListItem>
        Once the poll ends, each option will be weighed in proportion to the
        collective voted $BLT in said poll.
      </ListItem>
    </OrderedList>

    <Box
      pl={{ base: '0', md: '24px' }}
      lineHeight={{ base: '20px', md: '26px' }}
    >
      <Text fontWeight="600">For example:</Text>
      <UnorderedList marginInlineStart="1.5em">
        <ListItem>
          Total BLT in-stake amount of users who voted is 100,000.
        </ListItem>
        <ListItem>
          There are 3 options (X, Y, Z) and 4 users (A, B, C, D) voted:
        </ListItem>

        <UnorderedList marginInlineStart="2em">
          <ListItem>User A: 100 BLT in-stake, vote for option X</ListItem>
          <ListItem>User B: 1,000 BLT in-stake, vote for option X</ListItem>
          <ListItem>User C: 10,000 BLT in-stake, vote for option Y</ListItem>
          <ListItem>User D: 88,900 BLT in-stake, vote for option Z</ListItem>
        </UnorderedList>
      </UnorderedList>

      <Text fontWeight="600" lineHeight="26px" mt="20px">
        Result - approval rating:
      </Text>
      <UnorderedList marginInlineStart="1.5em" lineHeight="26px">
        <ListItem>Option X: 1,100 / 100,000 = 1.1%</ListItem>
        <ListItem>Option Y: 10,000 / 100,000 = 10%</ListItem>
        <ListItem>Option Z: 88,900 / 100,000 = 88.9%</ListItem>
      </UnorderedList>
      <Text fontWeight="700" marginInlineStart="0.2em" lineHeight="26px">
        → Option Z will be the final decision.
      </Text>
    </Box>
  </>
);

export default Rules;
