import { Box, Flex, Heading, Text, useBreakpointValue } from '@chakra-ui/react';
import { FC, useEffect, useRef, useState } from 'react';
import { measureTextWidth } from 'utils/measureTextWidth';

interface VisualizedOptionStatsProps {
  name: string;
  stakedBlt: number;
  percentage: number;
  isLeadingOption: boolean;
}

const VisualizedOptionStats: FC<VisualizedOptionStatsProps> = ({
  name,
  stakedBlt,
  percentage,
  isLeadingOption,
}) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const nameRef = useRef<HTMLHeadingElement | null>(null);
  const statsRef = useRef<HTMLHeadingElement | null>(null);
  const isSmallerThanMd = useBreakpointValue({ base: true, md: false });
  const [hasTooManyCharacters, setHasTooManyCharacters] = useState(false);

  useEffect(() => {
    if (
      isSmallerThanMd !== undefined &&
      containerRef.current &&
      nameRef.current &&
      statsRef.current
    ) {
      const nameTextWidth = measureTextWidth(
        nameRef.current,
        "16px 'Work Sans'"
      );
      const statsTextWidth = measureTextWidth(
        statsRef.current,
        isSmallerThanMd ? "14px 'Work Sans'" : "16px 'Work Sans'"
      );
      setHasTooManyCharacters(
        nameTextWidth + statsTextWidth >= containerRef.current.offsetWidth
      );
    }
  }, [isSmallerThanMd, name, stakedBlt, percentage]);

  return (
    <Box
      mt={{ base: '30px', md: '24px' }}
      _first={{ mt: { base: '30px', md: '20px' } }}
    >
      <Flex
        ref={containerRef}
        justifyContent="space-between"
        flexDirection={hasTooManyCharacters ? 'column' : undefined}
      >
        <Heading
          ref={nameRef}
          as="span"
          variant="h5"
          d={hasTooManyCharacters ? 'block' : undefined}
          fontWeight="500"
        >
          {name}
        </Heading>
        <Text
          ref={statsRef}
          as="span"
          d={hasTooManyCharacters ? 'block' : undefined}
          textAlign={hasTooManyCharacters ? 'right' : undefined}
          color="secondary"
          fontSize={{ base: '14px', md: '16px' }}
          lineHeight={{ base: '18px', md: '20px' }}
        >
          {new Intl.NumberFormat('en-US', {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          }).format(stakedBlt)}{' '}
          Votes (
          {new Intl.NumberFormat('en-US', {
            style: 'percent',
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          }).format(percentage)}
          )
        </Text>
      </Flex>
      <Box
        width="100%"
        height="12px"
        bg="lightGray"
        borderRadius="50px"
        mt="8px"
        _after={{
          content: '""',
          display: 'block',
          width: `max(${percentage * 100}%, 12px)`,
          height: '12px',
          background: isLeadingOption
            ? 'linear-gradient(90deg, #0076E2 0%, #31C1FF 100.99%)'
            : 'gray',
          borderRadius: '50px',
          transition: '0.3s width',
        }}
      />
    </Box>
  );
};

export default VisualizedOptionStats;
