import { Icon } from '@chakra-ui/react';
import { FC } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Menu: FC<{ fill?: string; [key: string]: any }> = ({ ...rest }) => {
  return (
    <Icon boxSize="24px" viewBox="0 0 24 24" {...rest}>
      <rect x="3" y="5" width="18" height="2" rx="1" fill="#7F7F7F" />
      <rect x="3" y="11" width="18" height="2" rx="1" fill="#7F7F7F" />
      <rect x="3" y="17" width="18" height="2" rx="1" fill="#7F7F7F" />
    </Icon>
  );
};

export default Menu;
