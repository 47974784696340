import {
  SerializedTopicType,
  TopicState,
  TopicInfo,
  TopicStatsVotesType,
  TopicType,
} from 'types';

interface SerializedTopicParamType extends TopicType {
  id?: number;
  state?: TopicState;
  hasVoted?: boolean;
  hasFetchedVotes?: boolean;
  leadingOption?: number;
  myRank?: number;
  canCurrentUserVote?: boolean;
  isVoting?: boolean;
  // Attributes from API
  distribution?: number[];
  totalVotes?: number;
  uniqueWallets?: number;
  votes?: TopicStatsVotesType[];
  shouldWeightVotingPower?: boolean;
  slug?: string;
}

export const serializeTopic = (
  topic: SerializedTopicParamType,
  id: number,
  stats: Partial<TopicInfo> = {},
  votedOptions: {
    topicId: number;
    optionIndex: number;
  }[],
  slug: string | undefined
): SerializedTopicType => {
  const isStarted = Number(topic.startAt) * 1000 <= Date.now();
  const isEnded = Number(topic.endAt) * 1000 <= Date.now();
  let state = TopicState.ENDED;
  if (isStarted && !isEnded) {
    state = TopicState.ACTIVE;
  } else if (!isStarted && !isEnded) {
    state = TopicState.UPCOMING;
  }

  let distribution = topic.sealed
    ? topic.votesCountActual.map((vote) => (vote ? +vote : 0))
    : stats.statistics?.distribution ?? [];
  let noOneVoted = false;
  const hasNullValue = stats.statistics?.distribution?.some(
    (amount) => amount === null
  );

  if (!stats.statistics?.distribution?.length) {
    distribution = new Array(topic.options.length).fill(0);
    noOneVoted = true;
  }

  if (stats.statistics?.distribution && hasNullValue) {
    distribution = stats.statistics.distribution.map((amount) =>
      amount === null ? 0 : amount
    );
  }

  if (distribution.length !== topic.options.length) {
    const difference = new Array(
      topic.options.length - distribution.length
    ).fill(0);
    distribution = distribution.concat(difference);
  }

  const leadingOption = distribution
    .map((amount, index) => ({ id: index, amount }))
    .reduce((result, current) =>
      +result.amount > +current.amount ? result : current
    ).id;

  return {
    ...topic,
    id,
    slug,
    state,
    userVotedOptionIndex: votedOptions.find((voted) => voted.topicId === id)
      ?.optionIndex,
    canCurrentUserVote:
      topic.canCurrentUserVote !== undefined
        ? topic.canCurrentUserVote
        : votedOptions.some((voted) => voted.topicId === id)
        ? true
        : undefined,
    distribution,
    leadingOption: noOneVoted ? undefined : leadingOption,
    hasFetchedVotes: !!topic.votes,
    totalVotes: topic.sealed
      ? distribution.reduce((sum, current) => (sum += current), 0)
      : stats.statistics?.totalVotes ?? 0,
    uniqueWallets: stats.statistics?.uniqueWallets ?? 0,
    shouldWeightVotingPower:
      stats.shouldWeightVotingPower ?? topic.shouldWeightVotingPower ?? true,
    isVoting: topic.isVoting ? topic.isVoting : false,
  };
};
