import { useScripts } from 'flow';
import {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';
import UserContext from 'context/user';
import {
  fetchAllTopicsStats,
  fetchSlugs,
  fetchTopicStats,
  syncVotesAndReload,
} from 'services';
import { DetailTopicStats, SerializedTopicType } from 'types';
import { serializeTopic } from 'utils/serializeTopic';

interface TopicsContextType {
  topics: SerializedTopicType[];
  topic?: SerializedTopicType;
  updateTopicCanVote?: (index: number, canCurrentUserVote: boolean) => void;
  updateTopicIsVoting?: (index: number, isVoting: boolean) => void;
  updateVotedOptions?: (indedx: number) => void;
}

const TopicsContext = createContext<TopicsContextType>({ topics: [] });

export const TopicsProvider: FC = ({ children }) => {
  const { id } = useParams();
  const { getTopics, getVotedOptions, checkCanVote } = useScripts();
  const { user } = useContext(UserContext);
  const hasPostedToApi = useRef(false);
  const [topics, setTopics] = useState<SerializedTopicType[]>([]);
  const [votedOptions, setVotedOptions] = useState<
    { topicId: number; optionIndex: number }[]
  >([]);
  const topicId = id
    ? Number.isNaN(+id)
      ? topics.find((topic) => topic.slug === id)?.id
      : +id - 1
    : undefined;

  const updateTopics = (stats: DetailTopicStats) => {
    setTopics((prevTopics) => {
      const myVoteIndex = stats.votes.findIndex(
        (vote) => vote.address === user?.addr
      );
      const copied = [...prevTopics];
      copied[stats.id] = serializeTopic(
        {
          ...copied[stats.id],
          votes: stats.votes,
          myRank: myVoteIndex === -1 ? -1 : myVoteIndex + 1,
        },
        stats.id,
        stats,
        votedOptions,
        copied[stats.id].slug
      );
      return copied;
    });
  };

  const fetchTopicStatsPer10secs = useCallback(
    (topicId) => {
      fetchTopicStats(topicId)
        .then((stats) => {
          const hasVoted = votedOptions.some(
            (voted) => voted.topicId === topicId
          );
          const noStatsFromApi = !stats.votes.some(
            (vote) => vote.address === user?.addr
          );

          if (!hasPostedToApi.current && hasVoted && noStatsFromApi) {
            syncVotesAndReload();
          } else {
            updateTopics(stats);
          }
          hasPostedToApi.current = false;
        })
        .catch(console.error);
    },
    [user?.addr, votedOptions]
  );

  const getVotedRecord = useCallback(() => {
    return new Promise<{ [key: string]: number }>((resolve, reject) => {
      if (!user?.addr) {
        reject(new Error('Please log in to get the voted record.'));
      } else {
        getVotedOptions(user.addr)
          .then((voted) => {
            resolve(voted ? voted : {});
          })
          .catch((error) => {
            console.error(error);
            reject(error);
          });
      }
    });
  }, [user?.addr]);

  const fetchAllStatsPer10secs = useCallback(() => {
    Promise.allSettled([
      getTopics(),
      fetchSlugs(),
      fetchAllTopicsStats(),
      getVotedRecord(),
    ]).then((result) => {
      const [
        topicsResult,
        slugsResult,
        allTopicsStatsResult,
        votedRecordResult,
      ] = result;

      const fetchedTopics =
        topicsResult.status === 'fulfilled' ? topicsResult.value : [];
      const fetchedSlugs =
        slugsResult.status === 'fulfilled' ? slugsResult.value : {};
      const stats =
        allTopicsStatsResult.status === 'fulfilled'
          ? allTopicsStatsResult.value
          : [];
      const voted =
        votedRecordResult.status === 'fulfilled' ? votedRecordResult.value : {};

      const votedResult = Object.entries(voted)
        .map(([v, i]) => [+v, +i])
        .map(([topicId, optionIndex]) => ({ topicId, optionIndex }));

      const hasNullValue = stats.find(
        (topicStats) => topicStats.statistics.totalVotes === null
      );
      if (hasNullValue) {
        syncVotesAndReload();
      }

      setTopics((prevTopics) =>
        fetchedTopics.map((topic, index) =>
          serializeTopic(
            prevTopics[index] ? { ...prevTopics[index], ...topic } : topic,
            index,
            stats[index],
            votedResult,
            fetchedSlugs[index]
          )
        )
      );
      setVotedOptions(votedResult);
    });
  }, [getTopics, getVotedRecord, user?.addr]);

  const refreshAllStats = useCallback(() => {
    setTopics((prevTopics) =>
      prevTopics.map((topic) => ({
        ...topic,
        hasFetchedVotes: false,
        hasVoted: false,
        isVoting: false,
        myRank: undefined,
        canCurrentUserVote: undefined,
      }))
    );
  }, [user?.addr]);

  const isSealed = topicId !== undefined ? topics[topicId]?.sealed : undefined;
  useEffect(() => {
    let fetchingAllTimer: NodeJS.Timeout | undefined;
    if (user?.isInitialized) {
      fetchAllStatsPer10secs();
      fetchingAllTimer = setInterval(fetchAllStatsPer10secs, 10000);
    } else {
      refreshAllStats();
    }

    return () => {
      if (fetchingAllTimer) {
        clearInterval(fetchingAllTimer);
      }
    };
  }, [getTopics, fetchAllStatsPer10secs, user, topicId, isSealed]);

  useEffect(() => {
    if (topicId !== undefined && topics.length) {
      const topic = topics[topicId];

      if (
        user?.addr &&
        !votedOptions.some((voted) => voted.topicId === topicId)
      ) {
        checkCanVote(user.addr, topicId)
          .then((canCurrentUserVote) => {
            updateTopicCanVote(topicId, canCurrentUserVote);
          })
          .catch((error) => {
            console.error(error);
            updateTopicCanVote(topicId, false);
          });
      }

      if (!topic?.sealed || !topic?.votes) {
        fetchTopicStatsPer10secs(topicId);
      }
    }
  }, [votedOptions]);

  const updateTopicCanVote = (index: number, canCurrentUserVote: boolean) => {
    setTopics((prevTopics) => {
      const copied = [...prevTopics];
      if (copied[index]) {
        copied[index].canCurrentUserVote = canCurrentUserVote;
      }
      return copied;
    });
  };

  const updateTopicIsVoting = (index: number, isVoting: boolean) => {
    setTopics((prevTopics) => {
      const copied = [...prevTopics];
      if (copied[index]) {
        copied[index].isVoting = isVoting;
      }
      return copied;
    });
  };

  const updateVotedOptions = (index: number) => {
    hasPostedToApi.current = true;
    getVotedRecord()
      .then((voted) => {
        setVotedOptions(
          Object.entries(voted)
            .map(([v, i]) => [+v, +i])
            .map(([topicId, optionIndex]) => ({ topicId, optionIndex }))
        );

        setTopics((prevTopics) => {
          const copied = [...prevTopics];
          copied[index] = {
            ...copied[index],
            userVotedOptionIndex: voted[index],
          };
          return copied;
        });
      })
      .catch((error) => console.error(error));
  };

  return (
    <TopicsContext.Provider
      value={{
        topics,
        topic: topicId !== undefined ? topics[topicId] : undefined,
        updateTopicCanVote,
        updateTopicIsVoting,
        updateVotedOptions,
      }}
    >
      {children}
    </TopicsContext.Provider>
  );
};

export default TopicsContext;
