import {
  Flex,
  Box,
  Button,
  Link as ChakraLink,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import { FC, useContext, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import UserContext from 'context/user';
import Cross from './icons/Cross';
import Logo from './icons/Logo';
import Menu from './icons/Menu';
import Wallet from './icons/Wallet';

const LogoContainer: FC = ({ children }) => {
  const { pathname } = useLocation();

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return pathname === '/' ? (
    <Button border="none" padding="0" onClick={scrollToTop}>
      {children}
    </Button>
  ) : (
    <ChakraLink
      as={Link}
      to="/"
      _hover={{
        opacity: 0.8,
        transform: 'scale(0.97)',
      }}
      _active={{
        transform: 'scale(0.94)',
      }}
    >
      {children}
    </ChakraLink>
  );
};

const Header: FC = () => {
  const { user, login, logout } = useContext(UserContext);
  const isSmallerThanMd = useBreakpointValue({ base: true, md: false });
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen((prevIsMenuOpen) => !prevIsMenuOpen);
  };

  const logOutAndCloseModal = () => {
    if (logout) {
      logout();
    }
    setIsMenuOpen(false);
  };

  return (
    <Box
      as="header"
      zIndex="banner"
      position="fixed"
      width="100%"
      top="0"
      transition="transform 400ms ease-in"
    >
      <Flex
        pl={{ base: '0', md: '30px' }}
        pr={{ base: '0', md: '30px' }}
        alignItems="center"
        height={76}
        w="100%"
        bg={{ base: 'none', md: 'rgba(255, 255, 255, 0.7)' }}
        backdropFilter={{ base: 'unset', md: 'blur(20px)' }}
        justifyContent="space-between"
        boxShadow={{
          base: 'none',
          md: 'inset 0px -0.5px 0px rgba(188, 188, 188, 0.4)',
        }}
      >
        <Flex
          pl={{ base: '20px', md: '0' }}
          pr={{ base: '10px', md: '0' }}
          alignItems="center"
          height={76}
          w="100%"
          bg={{ base: 'rgba(255, 255, 255, 0.7)', md: 'none' }}
          backdropFilter={{ base: 'blur(20px)', md: 'unset' }}
          justifyContent="space-between"
          boxShadow={{
            base: 'inset 0px -0.5px 0px rgba(188, 188, 188, 0.4)',
            md: 'none',
          }}
        >
          <LogoContainer>
            <Logo />
          </LogoContainer>
          <Button
            d={{ base: 'block', md: 'none' }}
            padding="0"
            border="none"
            onClick={toggleMenu}
          >
            {isMenuOpen ? <Cross /> : <Menu />}
          </Button>
        </Flex>

        <Box
          d="block"
          position={{ base: 'absolute', md: 'relative' }}
          top={{
            base:
              (isSmallerThanMd !== undefined && !isSmallerThanMd) || isMenuOpen
                ? '100%'
                : '-350%',
            md: 'unset',
          }}
          left={{ base: 0, md: 'unset' }}
          zIndex={{ base: '-1', md: '0' }}
          width={{ base: '100%', md: 'unset' }}
          bg={{ base: 'unset', md: 'white' }}
          boxShadow={{ base: '0px 4px 8px rgba(0, 0, 0, 0.05)', md: 'unset' }}
          transition="0.3s top"
          _hover={{
            '> div:nth-of-type(2)': { top: '100%' },
            '> div:last-child': { display: user?.addr ? 'block' : undefined },
          }}
        >
          {!isSmallerThanMd || !user?.addr ? (
            <Box
              padding={{ base: '30px 20px 40px', md: '0' }}
              bg={{ base: 'rgba(255, 255, 255, 0.7)', md: 'none' }}
              backdropFilter={{ base: 'blur(20px)', md: 'unset' }}
            >
              <Button
                width={{ base: '100%', md: 'unset' }}
                minW={110}
                h="48px"
                d="flex"
                alignItems="center"
                px="20px"
                bg="primary"
                border="none"
                borderRadius="100px"
                onClick={!user?.addr ? login : undefined}
              >
                <Wallet />

                <Text color="white" ml="6px">
                  {user?.addr
                    ? user.displayAddr
                    : isSmallerThanMd
                    ? 'Connect Wallet'
                    : 'Wallet'}
                </Text>
              </Button>
            </Box>
          ) : null}

          <Box
            width="100%"
            height="30px"
            position="absolute"
            top="0"
            zIndex="0"
            display={{ base: 'none', md: 'block' }}
          />

          {!isSmallerThanMd || user?.addr ? (
            <Box
              display={{ base: 'block', md: 'none' }}
              width={{ base: '100%', md: '240px' }}
              borderRadius={{ base: '0', md: '5px' }}
              bg={{ base: 'rgba(255, 255, 255, 0.7)', md: 'white' }}
              backdropFilter={{ base: 'blur(20px)', md: 'unset' }}
              position={{ base: 'relative', md: 'absolute' }}
              right="0"
              top="calc(100% + 30px)"
              boxShadow={{
                base: 'none',
                md: '0px 0px 10px rgba(0, 0, 0, 0.0507266)',
              }}
            >
              <Box
                p="15px 20px"
                boxShadow={{
                  base: 'inset 0px -0.2px 0px rgb(188, 188, 188)',
                  md: 'none',
                }}
              >
                <Text fontWeight="500" mb="10px">
                  Your Address
                </Text>
                <Text color="secondary">{user?.addr}</Text>
              </Box>
              <Button
                width="100%"
                height={{ base: '68px', md: '55px' }}
                px="20px"
                fontWeight="400"
                justifyContent="flex-start"
                border="none"
                onClick={logOutAndCloseModal}
              >
                Sign Out
              </Button>
            </Box>
          ) : null}
        </Box>
      </Flex>
    </Box>
  );
};

export default Header;
