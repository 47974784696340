import dayjs from 'services/day';

export const formatDurationString = (start: number, end: number): string => {
  const duration = end - start;

  return duration < 0
    ? '0d : 00h : 00m : 00s'
    : `${Math.floor(dayjs.duration(duration).asDays())}d : ${dayjs
        .utc(duration)
        .format('HH[h] : mm[m] : ss[s]')}`;
};
