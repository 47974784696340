import { ChakraProvider } from '@chakra-ui/react';
import AOS from 'aos';
import { FC, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { UserProvider } from 'context/user';
import { TopicsProvider } from 'context/topics';
import IndexPage from 'pages/IndexPage';
import Topic from 'pages/Topic';
import { syncVotesAndReload } from 'services/syncVotes';
import { theme } from 'theme';
import { tagManagerArgs } from 'constants/index';
import TagManager from 'react-gtm-module';

import 'aos/dist/aos.css';

import 'aos/dist/aos.css';

const App: FC = () => {
  useEffect(() => {
    TagManager.initialize(tagManagerArgs);
    AOS.init({ once: true });
    window.addEventListener('error', syncVotesAndReload);
    return () => window.removeEventListener('error', syncVotesAndReload);
  }, []);

  return (
    <ChakraProvider theme={theme}>
      <UserProvider>
        <Router>
          <Routes>
            <Route
              path="/"
              element={
                <TopicsProvider>
                  <IndexPage />
                </TopicsProvider>
              }
            />
            <Route
              path="/:id"
              element={
                <TopicsProvider>
                  <Topic />
                </TopicsProvider>
              }
            />
          </Routes>
        </Router>
      </UserProvider>
    </ChakraProvider>
  );
};

export default App;
