import { Box, useStyleConfig } from '@chakra-ui/react';
import { FC } from 'react';
import { TopicState, VotingState } from 'types';

const Types = {
  [TopicState.UPCOMING]: {
    text: 'Upcoming',
    color: 'brightBlue',
  },
  [TopicState.ACTIVE]: {
    text: 'Active',
    color: 'primary',
  },
  [TopicState.ENDED]: {
    text: 'Ended',
    color: 'secondary',
  },
  [VotingState.VOTED]: {
    text: 'Voted',
    color: 'primary',
  },
  [VotingState.HAS_NOT_VOTED]: {
    text: "Haven't Voted",
    color: 'secondary',
  },
};

interface LabelProps {
  type: TopicState | VotingState;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

const Label: FC<LabelProps> = ({ type, ...rest }) => {
  const styles = useStyleConfig('Label', { variant: Types[type].color });
  return (
    <Box __css={styles} {...rest}>
      {Types[type].text}
    </Box>
  );
};

export default Label;
