import { Icon } from '@chakra-ui/react';
import { FC } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Telegram: FC<{ fill?: string; [key: string]: any }> = ({ ...rest }) => {
  return (
    <Icon boxSize="24px" viewBox="0 0 24 24" {...rest}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.3199 19.8232C17.5882 20.0056 17.9341 20.0512 18.2424 19.9392C18.5507 19.8264 18.7774 19.5736 18.8457 19.2672C19.5699 16 21.3265 7.7304 21.9856 4.7584C22.0356 4.5344 21.9523 4.3016 21.769 4.152C21.5857 4.0024 21.3315 3.9592 21.1048 4.04C17.6107 5.2816 6.85015 9.1576 2.45192 10.72C2.17276 10.8192 1.9911 11.0768 2.00026 11.3592C2.01026 11.6424 2.20859 11.888 2.49442 11.9704C4.46687 12.5368 7.05598 13.3248 7.05598 13.3248C7.05598 13.3248 8.26595 16.8328 8.89677 18.6168C8.97594 18.8408 9.15843 19.0168 9.39926 19.0776C9.63926 19.1376 9.89592 19.0744 10.0751 18.912C11.0884 17.9936 12.655 16.5736 12.655 16.5736C12.655 16.5736 15.6316 18.6688 17.3199 19.8232ZM8.14512 12.8816L9.54426 17.312L9.85508 14.5064C9.85508 14.5064 15.2608 9.8256 18.3424 7.1576C18.4324 7.0792 18.4449 6.948 18.3699 6.856C18.2957 6.764 18.1591 6.7424 18.0566 6.8048C14.485 8.9944 8.14512 12.8816 8.14512 12.8816Z"
        fill="white"
      />
    </Icon>
  );
};

export default Telegram;
