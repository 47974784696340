const fileName =
  process.env.REACT_APP_ENVIRONMENT === 'development'
    ? 'testnet.json'
    : 'mainnet.json';

export const fetchSlugs = (): Promise<{ [key: string]: string }> => {
  return new Promise<{ [key: string]: string }>((resolve, reject) => {
    fetch(
      `https://api.github.com/repos/portto/blocto-dao-slugs/contents/${fileName}`,
      { method: 'GET' }
    )
      .then((result) => {
        result.json().then((data) => {
          const decodedContent = data.content
            ? JSON.parse(atob(data.content))
            : {};
          resolve(decodedContent);
        });
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
