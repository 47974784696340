import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as fcl from '@onflow/fcl';

const isDevelopment = process.env.REACT_APP_ENVIRONMENT === 'development';
const WALLET_URL = isDevelopment
  ? 'https://flow-wallet-testnet.blocto.app/authn'
  : 'https://flow-wallet.blocto.app/authn';

fcl
  .config()
  .put(
    'accessNode.api',
    isDevelopment
      ? 'https://access-testnet.onflow.org'
      : 'https://access-mainnet-beta.onflow.org'
  )
  .put('discovery.wallet', WALLET_URL);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
