import { Icon } from '@chakra-ui/react';
import { FC } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Cross: FC<{ fill?: string; [key: string]: any }> = ({ ...rest }) => {
  return (
    <Icon boxSize="24px" viewBox="0 0 24 24" {...rest}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.28374 18.7163C5.69588 19.1284 6.40586 19.0866 6.86952 18.623L12 13.4925L17.1305 18.623L17.2334 18.7159C17.694 19.0903 18.3336 19.099 18.7163 18.7163C19.1284 18.3041 19.0866 17.5941 18.623 17.1305L13.4925 12L18.623 6.86952L18.7159 6.76659C19.0903 6.30595 19.099 5.66644 18.7163 5.28374C18.3041 4.87159 17.5941 4.91336 17.1305 5.37702L12 10.5075L6.86952 5.37702L6.76659 5.28413C6.30595 4.90971 5.66644 4.90103 5.28374 5.28374C4.87159 5.69588 4.91336 6.40586 5.37702 6.86952L10.5075 12L5.37702 17.1305L5.28413 17.2334C4.90971 17.694 4.90103 18.3336 5.28374 18.7163Z"
        fill="#7F7F7F"
      />
    </Icon>
  );
};

export default Cross;
