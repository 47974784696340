import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import { FC, useContext } from 'react';
import Label from 'components/Label';
import UserContext from 'context/user';
import { SerializedTopicType, TopicState, VotingState } from 'types';
import { formatUTCString } from 'utils/formatUTCString';
import UntilNextState from './UntilNextState';

interface TopicHeaderProps {
  topic: SerializedTopicType;
  biggerStateText?: boolean;
  showFixedState?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

const TopicHeader: FC<TopicHeaderProps> = ({
  topic,
  biggerStateText,
  showFixedState,
  ...rest
}) => {
  const { user } = useContext(UserContext);

  return (
    <Box {...rest}>
      <Flex
        justifyContent="space-between"
        alignItems={{ base: 'flex-start', md: 'center' }}
        flexDirection={{ base: 'column', md: 'row' }}
      >
        <Heading
          as="h5"
          variant={biggerStateText && !showFixedState ? 'h4' : 'h5'}
          minHeight="20px"
          order={{ base: 1, md: 'unset' }}
          bg={showFixedState ? 'rgba(240, 248, 255, 0.7)' : 'none'}
          backdropFilter="blur(20px)"
          position={showFixedState ? 'fixed' : undefined}
          top={showFixedState ? '76px' : undefined}
          left={showFixedState ? '0' : undefined}
          zIndex="1"
          width={showFixedState ? '100%' : undefined}
          padding={showFixedState ? '8px 20px' : undefined}
        >
          {topic.state === TopicState.ACTIVE
            ? 'End in - '
            : topic.state === TopicState.UPCOMING && 'Start in - '}
          {topic.state !== TopicState.ENDED && <UntilNextState topic={topic} />}
        </Heading>
        <Box mb={{ base: showFixedState ? '6px' : '20px', md: 'unset' }}>
          <Label type={topic.state} />
          {user?.addr && (
            <Label
              type={
                topic.userVotedOptionIndex != null
                  ? VotingState.VOTED
                  : VotingState.HAS_NOT_VOTED
              }
              ml="20px"
            />
          )}
        </Box>
      </Flex>

      <Text color="secondary" mt={{ base: '10px', md: '6px' }}>
        {formatUTCString(Number(topic.startAt) * 1000)} ~{' '}
        {formatUTCString(Number(topic.endAt) * 1000)}
      </Text>
    </Box>
  );
};

export default TopicHeader;
