import { Icon } from '@chakra-ui/react';
import { FC } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Check: FC<{ fill?: string; [key: string]: any }> = ({ ...rest }) => {
  return (
    <Icon boxSize="28px" viewBox="0 0 28 28" {...rest}>
      <circle cx="14" cy="14" r="14" fill="white" />
      <path
        d="M14 0C6.2807 0 0 6.2807 0 14C0 21.7193 6.2807 28 14 28C21.7193 28 28 21.7193 28 14C28 6.2807 21.7193 0 14 0ZM20.7068 10.8421L13.0376 18.4511C12.5865 18.9023 11.8647 18.9323 11.3835 18.4812L7.32331 14.782C6.84211 14.3308 6.81203 13.5789 7.23308 13.0977C7.68421 12.6165 8.43609 12.5865 8.91729 13.0376L12.1353 15.985L18.9925 9.12782C19.4737 8.64662 20.2256 8.64662 20.7068 9.12782C21.188 9.60902 21.188 10.3609 20.7068 10.8421Z"
        fill="#0A94FF"
      />
    </Icon>
  );
};

export default Check;
