/* eslint-disable new-cap */
import { useCallback } from 'react';
import * as fcl from '@onflow/fcl';
import * as t from '@onflow/types';

const createTopicScript = `\
import BloctoDAO from ${process.env.REACT_APP_DAO_ADDRESS}

transaction(title: String, description: String, options: [String], startAt: Ufix64?, endAt: UFix64?, minVoteStakingAmount: UFix64?) {
  let proposer: &BloctoDAO.Proposer;
  prepare(signer: AuthAccount) {
    self.proposer = signer.getCapability(/public/bloctoDAOProposer).borrow<&BloctoDAO.Proposer>()
	    ?? panic("Could not borrow reference")
  }

  execute {
    self.proposer.addTopic(
      title: title,
      description: description, 
      options: options,
      startAt: startAt,
      endAt: endAt,
      minVoteStakingAmount: minVoteStakingAmount
    )
  }
}
`;

const voteScript = `\
import BloctoDAO from ${process.env.REACT_APP_DAO_ADDRESS}

transaction(topicId: UInt64, optionIndex: Int) {
  let voter: &BloctoDAO.Voter
  prepare(signer: AuthAccount) {

    if signer.borrow<&BloctoDAO.Voter>(from: BloctoDAO.VoterStoragePath) == nil {
      signer.save(<- BloctoDAO.initVoter(), to: BloctoDAO.VoterStoragePath)
      signer.link<&BloctoDAO.Voter{BloctoDAO.VoterPublic}>(BloctoDAO.VoterPublicPath, target: BloctoDAO.VoterStoragePath)
      signer.link<&BloctoDAO.Voter>(BloctoDAO.VoterPath, target: BloctoDAO.VoterStoragePath)
    }

    self.voter = signer.getCapability(BloctoDAO.VoterPath).borrow<&BloctoDAO.Voter>()
	    ?? panic("Could not borrow voter reference")
  }

  execute {
    self.voter.vote(topicId: topicId, optionIndex: optionIndex)
  }
}
`;

/* eslint-disable @typescript-eslint/no-explicit-any */
interface TransactionResponseType {
  account: any;
  block: any;
  blockHeader: any;
  collection: any;
  encodedData: any;
  events: any;
  latestBlock: any;
  tag: string;
  transaction: any;
  transactionId: string;
  transactionStatus: any;
}

interface UseTransactionsType {
  createTopic: (id: number) => Promise<any>;
  vote: (topicId: number, optionIndex: number) => Promise<any>;
}
/* eslint-enable @typescript-eslint/no-explicit-any */

const useTransactions = (): UseTransactionsType => {
  const createTransaction = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (script) => (args: any) =>
      fcl
        .send([fcl.getBlock(true)]) // get sealed blocks only
        .then(fcl.decode)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((block: any) =>
          fcl.send([
            fcl.transaction(script),
            fcl.args(args),
            fcl.limit(300),
            fcl.proposer(fcl.currentUser().authorization),
            fcl.authorizations([fcl.currentUser().authorization]),
            fcl.payer(fcl.currentUser().authorization),
            fcl.ref(block.id),
          ])
        )
        .then((response: TransactionResponseType) =>
          fcl.tx(response.transactionId).onceSealed()
        ),
    []
  );

  const createTopic = useCallback(
    ({ title, description, options, startAt, endAt, minVoteStakingAmount }) =>
      createTransaction(createTopicScript)([
        fcl.arg(title, t.String),
        fcl.arg(description, t.String),
        fcl.arg(options, t.Array(t.String)),
        fcl.arg(startAt, t.Optional(t.UFix64)),
        fcl.arg(endAt, t.Optional(t.UFix64)),
        fcl.arg(minVoteStakingAmount, t.Optional(t.UFix64)),
      ]),
    [createTransaction]
  );

  const vote = useCallback(
    (topicId, optionIndex) =>
      createTransaction(voteScript)([
        fcl.arg(topicId, t.UInt64),
        fcl.arg(optionIndex, t.Int),
      ]),
    [createTransaction]
  );

  return {
    createTopic,
    vote,
  };
};

export default useTransactions;
