import { Icon } from '@chakra-ui/react';
import { FC } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Twitter: FC<{ fill?: string; [key: string]: any }> = ({ ...rest }) => {
  return (
    <Icon boxSize="24px" viewBox="0 0 24 24" {...rest}>
      <path
        d="M22 5.92417C21.2642 6.25083 20.4733 6.47083 19.6433 6.57C20.4908 6.0625 21.1417 5.25833 21.4475 4.3C20.655 4.77 19.7767 5.11167 18.8417 5.29583C18.0942 4.49833 17.0267 4 15.8467 4C13.1975 4 11.2508 6.47167 11.8492 9.0375C8.44 8.86667 5.41667 7.23333 3.3925 4.75083C2.3175 6.595 2.835 9.0075 4.66167 10.2292C3.99 10.2075 3.35667 10.0233 2.80417 9.71583C2.75917 11.6167 4.12167 13.395 6.095 13.7908C5.5175 13.9475 4.885 13.9842 4.24167 13.8608C4.76333 15.4908 6.27833 16.6767 8.075 16.71C6.35 18.0625 4.17667 18.6667 2 18.41C3.81583 19.5742 5.97333 20.2533 8.29 20.2533C15.9083 20.2533 20.2125 13.8192 19.9525 8.04833C20.7542 7.46917 21.45 6.74667 22 5.92417Z"
        fill="white"
      />
    </Icon>
  );
};

export default Twitter;
