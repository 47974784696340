import { Text } from '@chakra-ui/react';
import { FC, useEffect, useState } from 'react';
import { SerializedTopicType } from 'types/topic';
import { getTopicDuration } from 'utils/getTopicDuration';

interface UntilNextStateProps {
  topic: SerializedTopicType;
}

const UntilNextState: FC<UntilNextStateProps> = ({ topic }) => {
  const [untilNextState, setUntilNextState] = useState(getTopicDuration(topic));

  const calculateDuration = () => {
    const duration = getTopicDuration(topic);
    setUntilNextState(duration);
  };

  useEffect(() => {
    calculateDuration();
    const timer = setInterval(calculateDuration, 1000);

    return () => clearInterval(timer);
  }, [topic]);

  return <Text as="span">{untilNextState}</Text>;
};

export default UntilNextState;
