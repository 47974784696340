import dayjs from 'services/day';

export const formatUTCString = (date: number): string => {
  const timezone = dayjs.tz.guess();

  const utcString = dayjs(dayjs.utc(date))
    .tz(timezone)
    .format('YYYY-MM-DD HH:mmZZ');
  const durationString = utcString?.split('+')?.[0] || '';
  const utcOffset = utcString?.split('+')?.[1] || '';

  return `${durationString} (UTC+${+utcOffset.slice(0, 2)})`;
};
