import { createContext, FC, useCallback, useEffect, useState } from 'react';
import * as fcl from '@onflow/fcl';
import { FlowUserType } from 'types/user';
import { formatAddress } from 'utils/formatAddress';

interface UserContextType {
  user?: FlowUserType;
  login?: () => void;
  logout?: () => void;
}

const UserContext = createContext<UserContextType>({});

export const UserProvider: FC = ({ children }) => {
  const [user, setUser] = useState<FlowUserType>({
    addr: null,
    displayAddr: null,
  });

  const login = useCallback(() => {
    if (!user?.addr) fcl.authenticate();
  }, [user]);

  const logout = useCallback(() => {
    if (user?.addr) {
      setUser({
        addr: null,
        displayAddr: null,
        isInitialized: false,
      });
      fcl.unauthenticate();
    }
  }, [user]);

  useEffect(
    () =>
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      fcl.currentUser().subscribe((user: any) => {
        setUser({
          ...user,
          displayAddr: formatAddress(user.addr),
          isInitialized: true,
        });
      }),
    []
  );

  return (
    <UserContext.Provider value={{ user, login, logout }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserContext;
