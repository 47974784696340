const isDevelopment = process.env.REACT_APP_ENVIRONMENT === 'development';
const currentEnv = isDevelopment ? 'dev' : 'prod';
const GTM_ARG_LIST = {
  dev: {
    gtmId: 'GTM-TW95WDQ',
    auth: 'CftjnHziH5attkvRnGHWdQ',
    preview: 'env-2',
  },
  prod: {
    gtmId: 'GTM-TW95WDQ',
    auth: 'gfSGwTl1T2Sq0xOlvHSr2A',
    preview: 'env-1',
  },
};

export const tagManagerArgs = GTM_ARG_LIST[currentEnv];
