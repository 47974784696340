import { extendTheme } from '@chakra-ui/react';

// Overwrite the chakra components
const Button = {
  baseStyle: {
    border: '1px solid',
    borderColor: 'primary',
    borderRadius: '10px',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '20px',
    transition: '.2s opacity, .2s transform',
    WebkitTapHighlightColor: 'transparent',
    _focus: { boxShadow: 'none' },
    _hover: {
      opacity: { base: 1, md: 0.8 },
      transform: { base: 'none', md: 'scale(0.98)' },
    },
    _active: {
      transform: 'scale(0.96)',
    },
  },
  variants: {
    round: {
      borderRadius: '30px',
    },
  },
};

const Heading = {
  baseStyle: {
    fontFamily: "'Work Sans', Arial, Helvetica, sans-serif",
  },
  variants: {
    h1: {
      fontWeight: 600,
      fontSize: '36px',
      lineHeight: '40px',
    },
    h2: {
      fontWeight: 600,
      fontSize: '28px',
      lineHeight: '32px',
    },
    h3: {
      fontWeight: 600,
      fontSize: '24px',
      lineHeight: '28px',
    },
    h4: {
      fontWeight: 600,
      fontSize: '20px',
      lineHeight: '24px',
    },
    h5: {
      fontWeight: 600,
      fontSize: '16px',
      lineHeight: '20px',
    },
  },
};

const Link = {
  baseStyle: {
    _focus: { boxShadow: 'none' },
    _hover: { textDecoration: 'none' },
  },
};

// Custom styled components
const Card = {
  baseStyle: {
    p: { base: '30px 10px', md: '40px 30px' },
    borderRadius: '20px',
    boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.05)',
  },
};

const Label = {
  baseStyle: {
    display: 'inline-block',
    padding: '8px 20px',
    fontSize: '14px',
    lineHeight: '18px',
    border: '1px solid',
    borderRadius: '10px',
  },
  variants: {
    brightBlue: {
      borderColor: 'brightBlueAlpha600',
      color: 'brightBlue',
    },
    primary: {
      borderColor: 'primaryAlpha600',
      color: 'primary',
    },
    secondary: {
      borderColor: 'secondaryAlpha600',
      color: 'secondary',
    },
  },
};

export const theme = extendTheme({
  styles: {
    global: {
      body: {
        fontFamily: "'Work Sans', Arial, Helvetica, sans-serif",
        overflowX: 'hidden',
        color: 'bloctoBlack',
        fontSize: '16px',
        lineHeight: '20px',
      },
    },
  },
  colors: {
    primary: '#0A94FF',
    primaryAlpha600: 'rgba(10, 148, 255, 0.6)',
    secondary: '#7F7F7F',
    secondaryAlpha600: 'rgba(127, 127, 127, 0.6)',
    brightBlue: '#31C1FF',
    brightBlueAlpha600: 'rgba(49, 193, 255, 0.6)',
    lighterBlue: '#F0F8FF',
    lighterBlueAlpha500: 'rgba(240, 248, 255, 0.5)',
    lightBlue: '#F0F8FF',
    bloctoBlack: '#141414',
    gray: '#A8AEB4',
    lightGray: '#F9F9F9',
    borderColor: '#BCBCBC',
  },
  components: {
    Button,
    Heading,
    Link,
    // Custom components
    Card,
    Label,
  },
});
