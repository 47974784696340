import { DetailTopicStats } from 'types/topicStats';

export const fetchTopicStats = (id: number): Promise<DetailTopicStats> => {
  return new Promise<DetailTopicStats>((resolve, reject) => {
    fetch(`${process.env.REACT_APP_API_URL}/votes/${id}`, {
      method: 'GET',
    })
      .then((result) => {
        result.json().then((data) => {
          resolve(data);
        });
      })
      .catch((error) => {
        reject(error);
      });
  });
};
