import { Icon } from '@chakra-ui/react';
import { FC } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ArrowDown: FC<{ fill?: string; [key: string]: any }> = ({
  fill,
  ...rest
}) => {
  return (
    <Icon boxSize="24px" viewBox="0 0 24 24" {...rest}>
      <path
        d="M16.6986 11.1889C17.0219 10.9141 17.5163 10.9425 17.803 11.2524C18.0636 11.5341 18.0628 11.9514 17.8176 12.2315L17.7368 12.3111L13.2078 16.1622C12.5595 16.7134 11.6042 16.7476 10.918 16.2648L10.7845 16.1613L6.26278 12.3107C5.93977 12.0357 5.9105 11.5616 6.19742 11.2519C6.45825 10.9704 6.8906 10.9216 7.20974 11.1217L7.3018 11.1893L11.8235 15.0398C11.9024 15.1071 12.0134 15.1206 12.1052 15.0803L12.1697 15.04L16.6986 11.1889Z"
        fill={fill ?? '#3186C3'}
      />
    </Icon>
  );
};

export default ArrowDown;
