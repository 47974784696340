import { DetailTopicStats } from 'types/topicStats';

export const updateTopicStats = (
  id: number,
  address: string,
  optionIndex: number
): Promise<DetailTopicStats> => {
  return new Promise<DetailTopicStats>((resolve) => {
    fetch(`${process.env.REACT_APP_API_URL}/votes/${id}`, {
      method: 'POST',
      body: JSON.stringify({ address, optionIndex }),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((result) => {
        result.json().then((data) => {
          resolve(data);
        });
      })
      .catch((error) => {
        console.error(error);
      });
  });
};
