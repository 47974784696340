import { Button, Heading } from '@chakra-ui/react';
import { useTransactions } from 'flow';
import { FC, useContext, useState } from 'react';
import Select from 'components/Select';
import TopicsContext from 'context/topics';
import { updateTopicStats } from 'services/updateTopicStats';
import Tooltip from './Tooltip';

interface VoteInterfaceProps {
  topicId: number;
  isAppliedSquareRootMethod: boolean;
  options: string[];
  isVoting: boolean;
  address?: string | null;
}

const VoteInterface: FC<VoteInterfaceProps> = ({
  topicId,
  isAppliedSquareRootMethod,
  options,
  address,
  isVoting,
}) => {
  const { updateTopicIsVoting, updateVotedOptions } = useContext(TopicsContext);
  const { vote } = useTransactions();
  const [voteFor, setVoteFor] = useState<number | undefined>(undefined);

  const handleOnSelect = (index: number) => {
    setVoteFor(index);
  };

  const handleVote = () => {
    if (
      topicId !== undefined &&
      voteFor !== undefined &&
      address &&
      updateTopicIsVoting &&
      updateVotedOptions
    ) {
      updateTopicIsVoting(topicId, true);
      vote(topicId, voteFor)
        .then(() => {
          updateVotedOptions(topicId);
          updateTopicStats(topicId, address as string, voteFor);
          updateTopicIsVoting(topicId, false);
        })
        .catch((error) => {
          console.error(error);
          updateTopicIsVoting(topicId, false);
        });
    }
  };

  return (
    <>
      <Heading as="h3" variant="h3" display="flex" alignItems="center">
        Your Vote
        {isAppliedSquareRootMethod && (
          <Tooltip marginLeft="16px">
            Each option is weighed in proportion to the collective voted $BLT,
            with the square root method applied.
          </Tooltip>
        )}
      </Heading>
      <Select
        options={options}
        onChange={handleOnSelect}
        currentSelection={voteFor}
        mt={{ base: '24px', md: '30px' }}
        mb="20px"
        disabled={isVoting}
      />
      <Button
        variant="round"
        width="100%"
        height="44px"
        bg="primary"
        color="white"
        onClick={handleVote}
        disabled={voteFor === undefined || isVoting}
        isLoading={isVoting}
        sx={{
          _hover: {
            opacity: 0.8,
            transform:
              voteFor === undefined || isVoting ? 'none' : 'scale(0.98)',
            _disabled: { opacity: 0.4 },
          },
        }}
      >
        Vote
      </Button>
    </>
  );
};

export default VoteInterface;
