import { Box } from '@chakra-ui/react';
import { FC, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Header from './Header';

const Layout: FC = ({ children }) => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <Header />
      <Box
        maxWidth="1440px"
        m="76px auto 0"
        p={{ base: '20px 0 50px', md: '30px min(160px, 11%) 100px' }}
      >
        {children}
      </Box>
    </>
  );
};

export default Layout;
