import { Icon } from '@chakra-ui/react';
import { FC } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Wallet: FC<{ fill?: string; [key: string]: any }> = ({ ...rest }) => {
  return (
    <Icon boxSize="24px" viewBox="0 0 24 24" {...rest}>
      <path
        d="M18.8712 8.59058C19.3268 8.58986 19.6968 8.96716 19.6975 9.4333C19.6981 9.8606 19.3883 10.2142 18.9857 10.2707L18.8737 10.2786L5.13309 10.3002C4.67746 10.3009 4.30753 9.92364 4.30682 9.4575C4.30618 9.03021 4.61602 8.67659 5.01862 8.62007L5.13056 8.61218L18.8712 8.59058Z"
        fill="#fff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23 9.45894C23 6.97286 21.0301 4.95749 18.6 4.95749H5.4C2.96995 4.95749 1 6.97286 1 9.45894V18.4619C1 20.9479 2.96995 22.9633 5.4 22.9633H18.6C21.0301 22.9633 23 20.9479 23 18.4619V9.45894ZM5.4 6.64553H18.6L18.7808 6.65152C20.2153 6.74677 21.35 7.96729 21.35 9.45894V18.4619L21.3442 18.6468C21.251 20.1144 20.058 21.2753 18.6 21.2753H5.4L5.21919 21.2693C3.78469 21.174 2.65 19.9535 2.65 18.4619V9.45894L2.65585 9.27396C2.74896 7.80639 3.94197 6.64553 5.4 6.64553Z"
        fill="#fff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.2828 2.06444C17.4617 1.7705 18.6612 2.50996 18.9619 3.71608L19.2849 5.0115C19.2808 5.01146 19.2767 5.01144 19.2726 5.01144H17.5843L17.3609 4.11525C17.2941 3.84723 17.0498 3.67138 16.7893 3.68722L16.6912 3.70234L11.4407 5.01144H4.85001C4.00819 5.01144 3.2768 5.48425 2.90692 6.1788C3.10581 5.65744 3.54138 5.24123 4.11527 5.09814L16.2828 2.06444Z"
        fill="#fff"
      />
    </Icon>
  );
};

export default Wallet;
