import { Box, Flex, Heading, Text, useBreakpointValue } from '@chakra-ui/react';
import { FC, useContext } from 'react';
import Card from 'components/Card';
import JoinThePool from 'components/JoinThePool';
import Discord from 'components/icons/Discord';
import Network from 'components/icons/Network';
import Telegram from 'components/icons/Telegram';
import Twitter from 'components/icons/Twitter';
import Layout from 'components/Layout';
import LinkIcon from 'components/LinkIcon';
import PollStats from 'components/PollStats';
import PaginatedTable from 'components/PaginatedTable';
import Rules from 'components/Rules';
import TopicHeader from 'components/TopicHeader';
import TopicsContext from 'context/topics';
import { TopicState } from 'types/topic';

const externalLinks = [
  { icon: Telegram, href: 'https://t.me/blocto' },
  { icon: Discord, href: 'https://discord.com/invite/G9rmG3tVWr' },
  { icon: Twitter, href: 'https://twitter.com/BloctoApp' },
  { icon: Network, href: 'https://blocto.portto.io' },
];

const Topic: FC = () => {
  const { topic, updateTopicCanVote } = useContext(TopicsContext);
  const isSmallerThanMd = useBreakpointValue({ base: true, md: false });

  if (!topic || !updateTopicCanVote) {
    return <Layout />;
  }

  return (
    <Layout>
      <Card
        minHeight="342px"
        p={{ base: '32px 0 40px', md: '30px' }}
        mx={{ base: '20px', md: '0' }}
        display="flex"
        flexDirection="column"
        boxShadow={{
          base: 'inset 0px -0.2px 0px rgb(188, 188, 188)',
          md: '0px 0px 20px rgba(0, 0, 0, 0.05)',
        }}
        borderRadius={{ base: '0', md: '20px' }}
      >
        <TopicHeader
          topic={topic}
          mb={{ base: '20px', md: '30px' }}
          biggerStateText
          showFixedState={
            isSmallerThanMd && topic.state !== TopicState.ENDED ? true : false
          }
        />

        <Flex flex={1} flexDirection="column" justifyContent="space-between">
          <Box>
            <Heading as="h1" variant="h1">
              {topic.title}
            </Heading>
            <Text
              lineHeight={{ base: '26px', md: '24px' }}
              m={{ base: '20px 0 30px', md: '20px 0' }}
              whiteSpace="pre-wrap"
            >
              {topic.description}
            </Text>
          </Box>

          <Flex justifyContent={{ base: 'center', md: 'flex-end' }}>
            {externalLinks.map((link, index) => (
              <LinkIcon key={index} href={link.href} icon={link.icon} />
            ))}
          </Flex>
        </Flex>
      </Card>

      <Flex
        margin={{ base: '0', md: '24px 0 20px' }}
        d={{ base: 'block', md: 'flex' }}
      >
        <Card
          width={{ base: 'unset', md: '35%' }}
          m={{ base: '0 20px', md: '0 20px 0 0' }}
          p={{ base: '40px 0', md: '40px 30px' }}
          boxShadow={{
            base: 'inset 0px -0.2px 0px rgb(188, 188, 188)',
            md: '0px 0px 20px rgba(0, 0, 0, 0.05)',
          }}
          borderRadius={{ base: '0', md: '20px' }}
        >
          <JoinThePool topic={topic} />
        </Card>

        <Card
          flex="1"
          p={{ base: '40px 0', md: '40px 30px' }}
          mx={{ base: '20px', md: '0' }}
          boxShadow={{
            base: 'inset 0px -0.2px 0px rgb(188, 188, 188)',
            md: '0px 0px 20px rgba(0, 0, 0, 0.05)',
          }}
          borderRadius={{ base: '0', md: '20px' }}
        >
          <PollStats topic={topic} />
        </Card>
      </Flex>

      <Card
        p={{ base: '46px 0 40px', md: '40px 30px' }}
        mx={{ base: '20px', md: '0' }}
        boxShadow={{
          base: 'inset 0px -0.2px 0px rgb(188, 188, 188)',
          md: '0px 0px 20px rgba(0, 0, 0, 0.05)',
        }}
        borderRadius={{ base: '0', md: '20px' }}
      >
        <Rules isAppliedSquareRootMethod={topic.shouldWeightVotingPower} />
      </Card>

      <Card
        p="40px 0 0"
        mt={{ base: '0', md: '20px' }}
        boxShadow={{
          base: 'none',
          md: '0px 0px 20px rgba(0, 0, 0, 0.05)',
        }}
        borderRadius={{ base: '0', md: '20px' }}
      >
        <Heading
          as="h3"
          variant="h3"
          padding={{ base: '0 16px', md: '0 30px' }}
          mb={{ base: '0', md: '10px' }}
        >
          Voting by Address
        </Heading>
        {!topic?.sealed && (
          <Text
            padding={{ base: '0 16px', md: '0 30px' }}
            mt={{ base: '20px', md: '30px' }}
          >
            This is an estimation of the current poll status. Accurate voting
            percentage will be reflected after the poll has closed. 1 vote
            equals square root ($BLT).
          </Text>
        )}

        <PaginatedTable
          options={topic.options}
          totalVotes={topic.totalVotes}
          votes={topic.votes}
          myRank={topic.myRank}
        />
      </Card>
    </Layout>
  );
};

export default Topic;
