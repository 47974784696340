import { Box, Flex, Heading, Link as StyledLink, Text } from '@chakra-ui/react';
import { FC, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { SerializedTopicType, TopicState } from 'types/topic';
import { measureTextWidth } from 'utils/measureTextWidth';
import Card from './Card';
import TopicHeader from './TopicHeader';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ListItem: FC<{ topic: SerializedTopicType; [key: string]: any }> = ({
  topic,
  ...rest
}) => {
  const ref = useRef<HTMLHeadingElement | null>(null);
  const [hasTooManyCharacters, setHasTooManyCharacters] = useState(false);

  useEffect(() => {
    if (ref.current) {
      const textWidth = measureTextWidth(ref.current, "36px 'Work Sans'");
      setHasTooManyCharacters(textWidth >= ref.current.offsetWidth);
    }
  }, [topic]);

  return (
    <Card minHeight="334px" {...rest}>
      <TopicHeader
        topic={topic}
        mb={{ base: '20px', md: '30px' }}
        padding={{ base: '0 6px', md: '0' }}
      />

      <Flex d={{ base: 'block', md: 'flex' }}>
        <Box flex="1" padding={{ base: '0 6px', md: '0' }}>
          <Heading as="h2" variant="h2">
            {topic.title}
          </Heading>
          <Text
            lineHeight={{ base: '24px', md: '26px' }}
            m={{ base: '16px 0 0', md: '20px 0 30px' }}
            color="secondary"
            display="-webkit-box"
            overflow="hidden"
            sx={{
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: '2',
            }}
            whiteSpace="pre-wrap"
          >
            {topic.description}
          </Text>

          <StyledLink
            as={Link}
            to={`/${topic.slug ?? topic.id + 1}`}
            display={{ base: 'none', md: 'inline-block' }}
            height="44px"
            lineHeight="44px"
            padding="0 30px"
            bg="primary"
            borderRadius="50px"
            fontWeight="600"
            color="white"
            transition=".2s opacity, .2s transform"
            _hover={{
              opacity: 0.8,
              transform: 'scale(0.98)',
            }}
            _active={{
              transform: 'scale(0.96)',
            }}
          >
            Vote Detail
          </StyledLink>
        </Box>

        {topic.state !== TopicState.UPCOMING &&
        topic.leadingOption !== undefined ? (
          <Box m={{ base: '16px 0 20px', md: '0 0 0 9%' }}>
            <Heading as="h5" variant="h5" d={{ base: 'none', md: 'block' }}>
              {topic.sealed ? 'Result' : 'Leading option'}
            </Heading>
            <Flex
              flexDirection="column"
              justifyContent="center"
              alignItems={{ base: 'flex-start', md: 'center' }}
              width={{ base: '100%', md: '240px' }}
              minHeight={{ base: '0', md: '134px' }}
              padding={{ base: '16px', md: '26px 16px' }}
              mt={{ base: '0', md: '20px' }}
              borderRadius="10px"
              bg="lightBlue"
            >
              <Text d={{ base: 'block', md: 'none' }}>
                {topic.sealed ? 'Result' : 'Leading option'}
              </Text>
              <Flex
                flex="1"
                flexDirection={{ base: 'row', md: 'column' }}
                flexWrap="wrap"
                justifyContent={{ base: 'flex-start', md: 'center' }}
                alignItems={{ base: 'flex-end', md: 'center' }}
              >
                <Text
                  ref={ref}
                  width={{ base: 'unset', md: '100%' }}
                  mt={{ base: '8px', md: 0 }}
                  mr={{ base: '10px', md: '0' }}
                  fontWeight="600"
                  fontSize={{
                    base: hasTooManyCharacters ? '20px' : '24px',
                    md: hasTooManyCharacters ? '20px' : '36px',
                  }}
                  lineHeight={{
                    base: hasTooManyCharacters ? '24px' : '28px',
                    md: hasTooManyCharacters ? '24px' : '40px',
                  }}
                  textAlign={{ base: 'left', md: 'center' }}
                >
                  {topic.options[topic.leadingOption]}
                </Text>
                <Text mt="14px">
                  <Heading as="span" variant="h5">
                    {new Intl.NumberFormat('en-US', {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    }).format(topic.distribution[topic.leadingOption])}
                  </Heading>{' '}
                  Votes supporting.
                </Text>
              </Flex>
            </Flex>
          </Box>
        ) : null}

        <StyledLink
          as={Link}
          to={`/${topic.slug ?? topic.id + 1}`}
          display={{ base: 'inline-block', md: 'none' }}
          width="100%"
          height="44px"
          mt="20px"
          lineHeight="44px"
          bg="primary"
          borderRadius="20px"
          fontWeight="600"
          textAlign="center"
          color="white"
          _hover={{
            opacity: 0.8,
          }}
          _active={{
            transform: 'scale(0.98)',
          }}
        >
          Vote Detail
        </StyledLink>
      </Flex>
    </Card>
  );
};

export default ListItem;
