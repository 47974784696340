import { Box, Heading, Text } from '@chakra-ui/react';
import { FC, useContext } from 'react';
import JoinThePoolAction from 'components/JoinThePoolAction';
import UserContext from 'context/user';
import { SerializedTopicType, TopicState } from 'types/topic';
import Check from './icons/Check';
import Error from './icons/Error';

interface JoinThePoolProps {
  topic: SerializedTopicType;
}
const JoinThePool: FC<JoinThePoolProps> = ({ topic }) => {
  const { user } = useContext(UserContext);

  return (
    <>
      <Box
        id="join-the-pool"
        mb={{ base: '40px', md: '30px' }}
        sx={{
          scrollMarginTop: '120px',
        }}
      >
        <Heading as="h3" variant="h3" display="flex" alignItems="center">
          Join the Poll
        </Heading>
        {user?.addr ? (
          topic.hasFetchedVotes && topic.canCurrentUserVote !== undefined ? (
            <>
              <Text d="flex" alignItems="center" mt="30px">
                <Check mr="16px" />
                Blocto wallet connected.
              </Text>
              <Text d="flex" alignItems="center" mt="20px">
                {topic.canCurrentUserVote ? (
                  <Check mr="16px" />
                ) : (
                  <Error mr="16px" />
                )}
                Vote qualification - Staked BLT
              </Text>
              {topic.state !== TopicState.ENDED && !topic.canCurrentUserVote && (
                <Text m="20px 0 0 44px" color="primary">
                  You need to stake at least{' '}
                  {new Intl.NumberFormat('en-US', {
                    maximumFractionDigits: 20,
                  }).format(+topic.minVoteStakingAmount)}{' '}
                  BLT to qualify for voting.
                </Text>
              )}
            </>
          ) : null
        ) : (
          <Text mt={{ base: '20px', md: '30px' }}>
            Verify your BloctoDAO member status by connecting your wallet below!
          </Text>
        )}
      </Box>

      {(topic.hasFetchedVotes && topic.canCurrentUserVote !== undefined) ||
      !user?.addr ? (
        <JoinThePoolAction canVote={topic.canCurrentUserVote} />
      ) : null}
    </>
  );
};

export default JoinThePool;
