import { Icon } from '@chakra-ui/react';
import { FC } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Error: FC<{ fill?: string; [key: string]: any }> = ({ ...rest }) => {
  return (
    <Icon boxSize="28px" viewBox="0 0 28 28" {...rest}>
      <circle cx="14" cy="14" r="14" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28ZM18.0689 8.33132C18.5107 7.88956 19.2269 7.88956 19.6687 8.33132C20.079 8.7416 20.1082 9.3884 19.7565 9.83254L19.7511 9.83926L19.6689 9.93124L15.5997 14L19.6687 18.0689C20.1104 18.5107 20.1104 19.2269 19.6687 19.6687C19.2584 20.079 18.6116 20.1082 18.1675 19.7565L18.1607 19.7511L18.0688 19.6689L14 15.5997L9.93107 19.6687C9.48931 20.1104 8.77308 20.1104 8.33132 19.6687C7.92104 19.2584 7.89184 18.6116 8.24354 18.1675L8.24886 18.1607L8.33115 18.0688L12.4002 14L8.33132 9.93107C7.88956 9.48931 7.88956 8.77308 8.33132 8.33132C8.7416 7.92104 9.3884 7.89184 9.83254 8.24354L9.83926 8.24886L9.93124 8.33115L14 12.4002L18.0689 8.33132Z"
        fill="#BCBCBC"
      />
    </Icon>
  );
};

export default Error;
