import { Icon } from '@chakra-ui/react';
import { FC } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Discord: FC<{ fill?: string; [key: string]: any }> = ({ ...rest }) => {
  return (
    <Icon boxSize="24px" viewBox="0 0 24 24" {...rest}>
      <path
        d="M18.9308 6.24342C17.6561 5.66789 16.2892 5.24387 14.8599 5.00102C14.8339 4.99633 14.8079 5.00804 14.7945 5.03147C14.6187 5.33914 14.4239 5.74051 14.2876 6.05599C12.7503 5.82954 11.221 5.82954 9.71527 6.05599C9.57887 5.7335 9.37707 5.33914 9.20048 5.03147C9.18707 5.00883 9.16107 4.99711 9.13504 5.00102C7.70659 5.2431 6.33963 5.66712 5.06411 6.24342C5.05307 6.2481 5.04361 6.25592 5.03732 6.26606C2.44449 10.0776 1.73421 13.7954 2.08265 17.4672C2.08423 17.4852 2.09447 17.5023 2.10867 17.5133C3.81934 18.7494 5.47642 19.4998 7.10273 19.9973C7.12876 20.0051 7.15634 19.9957 7.1729 19.9746C7.55761 19.4577 7.90054 18.9126 8.19456 18.3394C8.21192 18.3059 8.19535 18.266 8.15989 18.2528C7.61594 18.0497 7.098 17.8022 6.59977 17.5211C6.56037 17.4984 6.55721 17.443 6.59347 17.4164C6.69831 17.3391 6.80318 17.2587 6.9033 17.1775C6.92141 17.1626 6.94665 17.1595 6.96794 17.1689C10.2411 18.6393 13.7846 18.6393 17.0191 17.1689C17.0404 17.1587 17.0657 17.1619 17.0846 17.1767C17.1847 17.2579 17.2895 17.3391 17.3952 17.4164C17.4314 17.443 17.4291 17.4984 17.3897 17.5211C16.8914 17.8076 16.3735 18.0497 15.8288 18.252C15.7933 18.2653 15.7775 18.3059 15.7949 18.3394C16.0952 18.9118 16.4381 19.4569 16.8157 19.9738C16.8315 19.9957 16.8599 20.0051 16.8859 19.9973C18.5201 19.4998 20.1772 18.7494 21.8879 17.5133C21.9028 17.5023 21.9123 17.4859 21.9139 17.468C22.3309 13.223 21.2154 9.53566 18.9568 6.26684C18.9513 6.25592 18.9419 6.2481 18.9308 6.24342ZM8.68335 15.2315C7.69792 15.2315 6.88594 14.3413 6.88594 13.248C6.88594 12.1548 7.68217 11.2646 8.68335 11.2646C9.69239 11.2646 10.4965 12.1626 10.4807 13.248C10.4807 14.3413 9.68451 15.2315 8.68335 15.2315ZM15.329 15.2315C14.3435 15.2315 13.5316 14.3413 13.5316 13.248C13.5316 12.1548 14.3278 11.2646 15.329 11.2646C16.338 11.2646 17.1421 12.1626 17.1264 13.248C17.1264 14.3413 16.338 15.2315 15.329 15.2315Z"
        fill="white"
      />
    </Icon>
  );
};

export default Discord;
