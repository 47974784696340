import { DetailTopicStats } from 'types/topicStats';

interface SyncVotesResponseType {
  totalTopics: number;
  result: Array<DetailTopicStats>;
}

export const syncVotes = (): Promise<SyncVotesResponseType> => {
  return new Promise<SyncVotesResponseType>((resolve) => {
    fetch(`${process.env.REACT_APP_API_URL}/syncVotes`, {
      method: 'POST',
    })
      .then((result) => {
        result.json().then((data) => {
          resolve(data);
        });
      })
      .catch((error) => {
        console.error(error);
      });
  });
};

export const syncVotesAndReload = (): void => {
  syncVotes().then(() => location.reload());
};
