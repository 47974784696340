import {
  Box,
  Button,
  Table,
  Tbody,
  Td as ChakraTd,
  Th as ChakraTh,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { FC, useState } from 'react';
import { TopicStatsVotesType } from 'types/topicStats';
import { formatAddress } from 'utils/formatAddress';
import ArrowDown from './icons/ArrowDown';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Th: FC<{ [key: string]: any }> = ({ children, ...rest }) => (
  <ChakraTh
    fontFamily="'Work Sans', Arial, Helvetica, sans-serif"
    padding={{ base: '30px 6vw', md: '30px' }}
    fontWeight="600"
    fontSize="16px"
    lineHeight="20px"
    borderBottom="none"
    textTransform="none"
    boxShadow="inset 0px -0.2px 0px rgb(188, 188, 188)"
    _first={{ pl: { base: '5.6vw', md: '30px' } }}
    _last={{ pr: { base: '5.6vw', md: '30px' } }}
    sx={{
      '@media screen and (max-width: 320px)': {
        padding: '30px 15px',
        ':first-of-type': {
          pl: '20px',
        },
      },
      '@media screen and (max-width: 280px)': {
        padding: '30px 10px',
        ':first-of-type': {
          pl: '16px',
        },
      },
    }}
    {...rest}
  >
    {children}
  </ChakraTh>
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Td: FC<{ [key: string]: any }> = ({ children, ...rest }) => (
  <ChakraTd
    padding={{ base: '30px 6vw', md: '30px' }}
    fontSize="16px"
    lineHeight="20px"
    borderBottom="none"
    _first={{ pl: { base: '5.6vw', md: '30px' } }}
    _last={{ pr: { base: '5.6vw', md: '30px' } }}
    sx={{
      '@media screen and (max-width: 320px)': {
        padding: '30px 15px',
        ':first-of-type': {
          pl: '20px',
        },
      },
      '@media screen and (max-width: 280px)': {
        padding: '30px 10px',
        ':first-of-type': {
          pl: '16px',
        },
      },
    }}
    {...rest}
  >
    {children}
  </ChakraTd>
);

interface VotingStatsTableProps
  extends Omit<PaginatedTableProps, 'itemPerPage'> {
  votes: TopicStatsVotesType[];
  myVote?: TopicStatsVotesType;
}

const VotingStatsTable: FC<VotingStatsTableProps> = ({
  options,
  totalVotes,
  votes,
  myRank,
  myVote,
}) => {
  return (
    <Table variant="simple">
      <Thead>
        <Tr>
          <Th>Rank</Th>
          <Th>Address</Th>
          <Th>Option</Th>
          <Th isNumeric>Voting Power</Th>
          <Th isNumeric>Votes Amount</Th>
        </Tr>
      </Thead>

      <Tbody>
        {myRank && myRank > 0 && myVote && (
          <Tr bg="lighterBlue" fontWeight="600">
            <Td>{myRank}</Td>
            <Td>Yours : {formatAddress(myVote.address)}</Td>
            <Td>{options[myVote.optionIndex]}</Td>
            <Td isNumeric>
              {new Intl.NumberFormat('en-US', {
                style: 'percent',
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              }).format(+myVote.amount / totalVotes)}
            </Td>
            <Td isNumeric>
              {new Intl.NumberFormat('en-US', {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              }).format(+myVote.amount)}{' '}
              Votes
            </Td>
          </Tr>
        )}

        {votes.map((vote, index) => (
          <Tr key={index}>
            <Td>{index + 1}</Td>
            <Td>{formatAddress(vote.address)}</Td>
            <Td>{options[vote.optionIndex]}</Td>
            <Td isNumeric>
              {new Intl.NumberFormat('en-US', {
                style: 'percent',
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              }).format(+vote.amount / totalVotes)}
            </Td>
            <Td isNumeric>
              {new Intl.NumberFormat('en-US', {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              }).format(+vote.amount)}{' '}
              Votes
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
};

interface PaginatedTableProps {
  options: string[];
  totalVotes: number;
  votes?: TopicStatsVotesType[];
  myRank?: number;
  itemPerPage?: number;
}

const PaginatedTable: FC<PaginatedTableProps> = ({
  options,
  totalVotes,
  votes,
  myRank,
  itemPerPage = 10,
}) => {
  const [page, setPage] = useState(1);

  const showNextPage = () => {
    setPage((prevPage) => ++prevPage);
  };

  const currentItems = votes ? votes.slice(0, itemPerPage * page) : [];
  const hasMore = votes ? votes.length > itemPerPage * page : false;
  const myVote =
    (myRank && myRank > 0 && votes && votes[myRank - 1]) || undefined;
  return (
    <>
      <Box overflow="auto" p={{ base: '0', md: '10px 0 36px' }}>
        <VotingStatsTable
          options={options}
          totalVotes={totalVotes}
          votes={currentItems}
          myRank={myRank}
          myVote={myVote}
        />
      </Box>

      {hasMore && (
        <Button
          width="100%"
          height="auto"
          padding="30px"
          border="none"
          boxShadow="inset 0px 0.2px 0px rgb(188, 188, 188)"
          borderRadius="0 0 20px 20px"
          color="#3186C3"
          fontWeight="400"
          onClick={showNextPage}
        >
          See more
          <ArrowDown ml="4px" />
        </Button>
      )}
    </>
  );
};

export default PaginatedTable;
