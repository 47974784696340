import { Box, Text } from '@chakra-ui/react';
import { FC, useState } from 'react';
import ArrowDown from './icons/ArrowDown';

interface SelectProps {
  options: string[];
  onChange: (index: number) => void;
  currentSelection?: number;
  disabled?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

const Select: FC<SelectProps> = ({
  options,
  onChange,
  currentSelection,
  disabled,
  ...rest
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleSelect = () => {
    if (disabled) {
      return;
    }
    setIsOpen((prevIsOpen) => !prevIsOpen);
  };

  const hasSelected =
    currentSelection !== undefined && options[currentSelection];
  return (
    <Box
      p="0 35px"
      height="44px"
      border="1px solid"
      borderColor={hasSelected ? 'primary' : 'borderColor'}
      borderRadius="25px"
      position="relative"
      cursor={disabled ? 'not-allowed' : 'pointer'}
      onClick={toggleSelect}
      sx={{ WebkitTapHighlightColor: 'transparent' }}
      {...rest}
    >
      <Text
        lineHeight="42px"
        color={hasSelected ? 'primary' : 'borderColor'}
        textAlign="center"
        borderRadius="25px"
        whiteSpace="nowrap"
        overflow="hidden"
        textOverflow="ellipsis"
      >
        {hasSelected ? options[currentSelection] : 'Your Vote'}
      </Text>

      <ArrowDown
        fill={hasSelected ? '#0A94FF' : '#7F7F7F'}
        position="absolute"
        top="50%"
        right="18px"
        transform="translateY(-50%)"
      />

      <Box
        width="100%"
        p="20px 0"
        bg="white"
        borderRadius="15px"
        position="absolute"
        top="calc(100% + 5px)"
        left="0"
        zIndex="1"
        boxShadow="0px 0px 10px rgba(0, 0, 0, 0.0507266)"
        opacity={isOpen ? 1 : 0}
        pointerEvents={isOpen ? 'auto' : 'none'}
        transition="0.2s opacity"
      >
        {options.map((option, index) => (
          <Box
            key={option}
            value={index}
            p="8px 20px"
            color="secondary"
            textAlign="left"
            _hover={{ background: 'lighterBlueAlpha500' }}
            onClick={() => onChange(index)}
          >
            {option}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default Select;
