import { SerializedTopicType, TopicState } from 'types/topic';
import { formatDurationString } from './formatDurationString';

export const getTopicDuration = (topic: SerializedTopicType): string => {
  switch (topic.state) {
    case TopicState.ACTIVE:
      return formatDurationString(Date.now(), Number(topic.endAt) * 1000);
    case TopicState.UPCOMING:
      return formatDurationString(Date.now(), Number(topic.startAt) * 1000);
    default:
      return '';
  }
};
