import { Flex, Heading, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { SerializedTopicType } from 'types/topic';
import Tooltip from './Tooltip';
import VisualizedOptionStats from './VisualizedOptionStats';

const PollStats: FC<{ topic: SerializedTopicType }> = ({ topic }) => {
  return (
    <>
      <Heading as="h3" variant="h3" display="flex" alignItems="center">
        Poll Breakdown
        {topic.shouldWeightVotingPower && (
          <Tooltip marginLeft="16px">
            Each option is weighed in proportion to the collective voted $BLT,
            with the square root method applied.
          </Tooltip>
        )}
      </Heading>
      {!topic.sealed && (
        <Text m={{ base: '20px 0 30px', md: '30px 0' }}>
          This is an estimation of the current poll status. Accurate voting
          percentage will be reflected after the poll has closed. 1 vote equals
          square root ($BLT).
        </Text>
      )}
      {topic.options.map((option, index) => (
        <VisualizedOptionStats
          key={option}
          name={option}
          stakedBlt={topic.distribution[index]}
          percentage={
            topic.distribution[index] && topic.totalVotes
              ? topic.distribution[index] / topic.totalVotes
              : 0
          }
          isLeadingOption={index === topic.leadingOption}
        />
      ))}
      <Heading as="h3" variant="h3" m={{ base: '40px 0', md: '40px 0 30px' }}>
        Poll Status
      </Heading>
      <Flex justifyContent="space-between">
        <Text as="span">Total votes</Text>
        <Heading as="span" variant="h5" fontWeight="500">
          {new Intl.NumberFormat('en-US', {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          }).format(topic.totalVotes)}{' '}
          Votes
        </Heading>
      </Flex>
      <Flex justifyContent="space-between" mt="12px">
        <Text as="span">Unique voters</Text>
        <Heading as="span" variant="h5" fontWeight="500">
          {topic.uniqueWallets}
        </Heading>
      </Flex>
    </>
  );
};

export default PollStats;
