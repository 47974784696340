import { Box } from '@chakra-ui/react';
import { FC } from 'react';
import Help from './icons/Help';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Tooltip: FC<{ [key: string]: any }> = ({ children, ...rest }) => {
  return (
    <Box
      position="relative"
      cursor="pointer"
      _hover={{ '> div': { opacity: 1 } }}
      {...rest}
    >
      <Help
        display="block"
        width="24px"
        height="24px"
        padding="4px"
        bg="gray"
        borderRadius="50%"
      />
      <Box
        opacity="0"
        width="200px"
        padding="12px"
        bg="gray"
        borderRadius="12px"
        color="white"
        fontSize="12px"
        fontWeight="400"
        lineHeight="18px"
        position="absolute"
        bottom="calc(100% + 10px)"
        left="50%"
        transform="translateX(-50%)"
        transition=".2s opacity"
        pointerEvents="none"
        _after={{
          content: '""',
          display: 'block',
          width: '14px',
          height: '14px',
          borderRadius: '2px',
          bg: 'gray',
          position: 'absolute',
          top: 'calc(100% - 8px)',
          left: '50%',
          transform: 'translateX(-50%) rotate(45deg)',
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default Tooltip;
