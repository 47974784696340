import { Link } from '@chakra-ui/react';
import { FC } from 'react';

const LinkIcon: FC<{ href: string; icon: FC }> = ({ href, icon }) => {
  const Icon = icon;
  return (
    <Link
      href={href}
      isExternal
      display="inline-flex"
      width="40px"
      height="40px"
      justifyContent="center"
      alignItems="center"
      bg="gray"
      borderRadius="10px"
      mx="10px"
      transition=".2s opacity, .2s transform"
      _first={{ ml: 0 }}
      _last={{ mr: 0 }}
      _hover={{
        opacity: 0.8,
        transform: 'scale(0.97)',
      }}
      _active={{
        transform: 'scale(0.94)',
      }}
    >
      <Icon />
    </Link>
  );
};

export default LinkIcon;
