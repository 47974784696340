import { Box, Heading, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { SerializedTopicType } from 'types/topic';
import Tooltip from './Tooltip';

const SelectedOption: FC<{
  topic: SerializedTopicType;
}> = ({ topic }) => {
  if (!topic.votes || topic.myRank === undefined || topic.myRank < 1) {
    return null;
  }

  const myVote = topic.votes[topic.myRank - 1];

  if (!myVote) {
    return null;
  }

  const optionIndex = myVote.optionIndex;
  return (
    <>
      <Heading
        as="h3"
        variant="h3"
        mt="40px"
        display="flex"
        alignItems="center"
      >
        Your Chosen Option
        {topic.shouldWeightVotingPower && (
          <Tooltip marginLeft="16px">
            Each option is weighed in proportion to the collective voted $BLT,
            with the square root method applied.
          </Tooltip>
        )}
      </Heading>
      {optionIndex !== undefined ? (
        <>
          {!topic.sealed && (
            <Text m="30px 0">
              This is an estimation of the current poll status. Accurate voting
              percentage will be reflected after the poll has closed. 1 vote
              equals square root ($BLT).
            </Text>
          )}
          <Box
            p="12px 8px"
            mt="30px"
            borderRadius="25px"
            bg={optionIndex === topic.leadingOption ? 'lighterBlue' : '#F9F9F9'}
            color={optionIndex === topic.leadingOption ? 'primary' : undefined}
            fontWeight="600"
            textAlign="center"
          >
            {topic.options[optionIndex]}
            {myVote
              ? ` / Voting power 
            ${new Intl.NumberFormat('en-US', {
              style: 'percent',
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            }).format(+myVote.amount / topic.totalVotes)}`
              : ''}
          </Box>
        </>
      ) : null}
    </>
  );
};

export default SelectedOption;
