import { Icon } from '@chakra-ui/react';
import { FC } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Help: FC<{ fill?: string; [key: string]: any }> = ({ fill, ...rest }) => {
  return (
    <Icon boxSize="16px" viewBox="0 0 16 16" {...rest}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.77122 3.44314C6.02793 3.44314 4.88887 4.49405 4.88887 5.50617C4.88887 5.90468 4.54065 6.22774 4.11109 6.22774C3.68154 6.22774 3.33331 5.90468 3.33331 5.50617C3.33331 3.4425 5.47164 2 7.77122 2C8.94233 2 10.1272 2.35764 11.0338 2.94583C11.9299 3.5272 12.6666 4.41648 12.6666 5.50617C12.6666 6.5785 11.9167 7.42122 10.4519 8.21723C9.37912 8.80022 8.88136 9.2976 8.64858 9.66912C8.42879 10.0199 8.40968 10.3141 8.43817 10.59C8.47912 10.9867 8.16569 11.3391 7.73809 11.3771C7.31049 11.4151 6.93065 11.1243 6.8897 10.7276C6.83965 10.2428 6.87697 9.62497 7.30373 8.94386C7.71751 8.28348 8.45646 7.62912 9.66569 6.972C10.967 6.26484 11.1111 5.80111 11.1111 5.50617C11.1111 5.05796 10.7997 4.55495 10.1424 4.12849C9.49553 3.70885 8.62155 3.44314 7.77122 3.44314Z"
        fill={fill ?? 'white'}
      />
      <ellipse
        cx="7.61112"
        cy="13.1843"
        rx="1.16667"
        ry="1.08235"
        fill={fill ?? 'white'}
      />
    </Icon>
  );
};

export default Help;
