import { useCallback } from 'react';
import * as fcl from '@onflow/fcl';
import * as t from '@onflow/types';
import { TopicType } from 'types/topic';

const getTopicsScript = `\
import BloctoDAO from ${process.env.REACT_APP_DAO_ADDRESS}

pub fun main(): [BloctoDAO.Topic] {
  return BloctoDAO.getTopics()
}
`;

const getTopicScript = `\
import BloctoDAO from ${process.env.REACT_APP_DAO_ADDRESS}

pub fun main(id: UInt64): BloctoDAO.Topic {
  return BloctoDAO.getTopic(id: id)
}
`;

const checkIsProposerScript = `\
import BloctoDAO from ${process.env.REACT_APP_DAO_ADDRESS}

pub fun main(account: Address): Bool {
  let proposer = getAccount(account).getCapability(/public/bloctoDAOProposer).borrow<&BloctoDAO.Proposer>()
  return proposer != nil
}
`;

const checkCanVoteScript = `\
import BloctoDAO from ${process.env.REACT_APP_DAO_ADDRESS}

pub fun main(address: Address, topicId: UInt64): Bool {
  let amount = BloctoDAO.getStakedBLT(address: address)
  let topic = BloctoDAO.getTopic(id: topicId)
  return amount >= topic.minVoteStakingAmount
}
`;

const getVotedOptionsScript = `\
import BloctoDAO from ${process.env.REACT_APP_DAO_ADDRESS}

pub fun main(account: Address): { UInt64: Int } {
  let voterPublic = getAccount(account).getCapability(BloctoDAO.VoterPublicPath).borrow<&BloctoDAO.Voter{BloctoDAO.VoterPublic}>()
    ?? panic("Can't borrow voter public reference")

  return voterPublic.getVotedOptions()
}
`;

interface UseScriptsType {
  getVotedOptions: (
    address: string
  ) => Promise<{ [key: number]: number } | undefined>;
  getTopics: () => Promise<TopicType[]>;
  getTopic: (id: number) => Promise<TopicType>;
  checkIsProposer: (address: string) => Promise<boolean>;
  checkCanVote: (address: string, topicId: number) => Promise<boolean>;
}

const useScripts = (): UseScriptsType => {
  const createScript = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (script) => (args: any) =>
      fcl.send([fcl.script(script), fcl.args(args)]).then(fcl.decode),
    []
  );

  const getTopics = useCallback(
    () => createScript(getTopicsScript)([]),
    [createScript]
  );

  const getTopic = useCallback(
    (id) => createScript(getTopicScript)([fcl.arg(id, t.UInt64)]),
    [createScript]
  );

  const checkIsProposer = useCallback(
    (address) =>
      createScript(checkIsProposerScript)([fcl.arg(address, t.Address)]),
    [createScript]
  );

  const checkCanVote = useCallback(
    (address, topicId) =>
      createScript(checkCanVoteScript)([
        fcl.arg(address, t.Address),
        fcl.arg(topicId, t.UInt64),
      ]),
    [createScript]
  );

  const getVotedOptions = useCallback(
    (address) =>
      createScript(getVotedOptionsScript)([fcl.arg(address, t.Address)]),
    [createScript]
  );

  return {
    getVotedOptions,
    getTopics,
    getTopic,
    checkIsProposer,
    checkCanVote,
  };
};

export default useScripts;
