import { Box, Button, Link, useBreakpointValue } from '@chakra-ui/react';
import { FC, useContext, useEffect, useRef, useState } from 'react';
import TopicsContext from 'context/topics';
import UserContext from 'context/user';
import { FlowUserType, SerializedTopicType, TopicState } from 'types';
import SelectedOption from './SelectedOption';
import VoteInterface from './VoteInterface';
import FixedButton from './FixedButton';

export enum ActionStatus {
  CONNECT_WALLET = 'connect-wallet',
  SHOW_SELECTED_OPTION = 'show-selected-option',
  WAIT_FOR_STAKING = 'wait-for-staking',
  VOTE = 'vote',
  NONE = 'none',
}

interface JoinThePoolActionProps {
  topic: SerializedTopicType;
  user?: FlowUserType;
  availableAction: ActionStatus;
  login?: () => void;
}

const JoinThePoolAction: FC<JoinThePoolActionProps> = ({
  topic,
  user,
  availableAction,
  login,
}) => {
  switch (availableAction) {
    case ActionStatus.CONNECT_WALLET:
      return (
        <Button
          variant="round"
          width="100%"
          height="44px"
          bg="primary"
          color="white"
          mt={{ base: '30px', md: '0' }}
          onClick={login}
        >
          Connect Wallet
        </Button>
      );

    case ActionStatus.SHOW_SELECTED_OPTION:
      return <SelectedOption topic={topic} />;

    case ActionStatus.WAIT_FOR_STAKING:
      return (
        <Link
          href="https://guide.blocto.app/article/blt-staking-program"
          isExternal
          d="block"
          height="44px"
          p="12px 20px"
          bg="primary"
          color="white"
          borderRadius="50px"
          textAlign="center"
          fontWeight="600"
          _hover={{
            opacity: 0.8,
            transform: 'scale(0.97)',
          }}
          _active={{
            transform: 'scale(0.94)',
          }}
        >
          Go to Stake
        </Link>
      );

    case ActionStatus.VOTE:
      return (
        <VoteInterface
          topicId={topic.id}
          isAppliedSquareRootMethod={topic.shouldWeightVotingPower}
          options={topic.options}
          address={user?.addr}
          isVoting={topic.isVoting}
        />
      );

    default:
      return null;
  }
};

const JoinThePoolActionContainer: FC<{ canVote?: boolean }> = ({ canVote }) => {
  const ref = useRef(null);
  const { topic } = useContext(TopicsContext);
  const { user, login } = useContext(UserContext);
  const isSmallerThanMd = useBreakpointValue({ base: true, md: false });
  const [hasShownActionSection, setHasShownActionSection] = useState(true);
  // Check if the topic exists and if the topic ends but the user didn't vote
  // If the topic ends and the user didn't vote, we show nothing.
  const hideAction =
    !topic ||
    (topic?.state === TopicState.ENDED &&
      !Number.isInteger(topic?.userVotedOptionIndex));

  useEffect(() => {
    const currentRef = ref.current;
    let intersectionObserver: IntersectionObserver | undefined;
    if (currentRef && !hideAction && isSmallerThanMd) {
      intersectionObserver = new IntersectionObserver(function (entries) {
        setHasShownActionSection(entries[0].isIntersecting);
      });
      intersectionObserver.observe(currentRef);
    }

    return () => {
      if (currentRef && intersectionObserver) {
        intersectionObserver.disconnect();
      }
    };
  }, [hideAction, isSmallerThanMd]);

  if (hideAction) {
    return null;
  }

  const availableAction = getAvailableAction(topic, canVote, user?.addr);
  return (
    <Box ref={ref}>
      <JoinThePoolAction
        topic={topic}
        user={user}
        availableAction={availableAction}
        login={login}
      />

      {isSmallerThanMd ? (
        <FixedButton
          isShown={!hasShownActionSection}
          availableAction={availableAction}
        />
      ) : null}
    </Box>
  );
};

export default JoinThePoolActionContainer;

const getAvailableAction = (
  topic: SerializedTopicType,
  canVote?: boolean,
  address?: string | null
): ActionStatus => {
  // Check if the user logs in
  if (!address) {
    return ActionStatus.CONNECT_WALLET;
  }

  // Make sure we had fetched the voting status
  if (!topic.hasFetchedVotes) {
    return ActionStatus.NONE;
  }

  // Show the selected option of the current user who has voted
  if (topic.userVotedOptionIndex != null) {
    return ActionStatus.SHOW_SELECTED_OPTION;
  }

  // Show the button to the current user who hasn't voted and is not qualified for voting
  if (canVote !== undefined && !canVote) {
    return ActionStatus.WAIT_FOR_STAKING;
  }

  // Show the voting interface
  if (topic.state === TopicState.ACTIVE) {
    return ActionStatus.VOTE;
  }

  return ActionStatus.NONE;
};
