import { Box, useBreakpointValue, useStyleConfig } from '@chakra-ui/react';
import { FC } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Card: FC<{ [key: string]: any }> = ({ children, ...rest }) => {
  const cardStyles = useStyleConfig('Card');
  const isSmallerThanMd = useBreakpointValue({ base: true, md: false });

  const aosAnimation = isSmallerThanMd
    ? {}
    : {
        'data-aos': 'fade-up',
        'data-aos-duration': '400',
        'data-aos-offset': '250',
      };
  return (
    // `aos-fix` is for fixing the elements overlapping issue (Ref: https://github.com/michalsnik/aos/issues/515)
    <Box className="aos-fix" __css={cardStyles} {...aosAnimation} {...rest}>
      {children}
    </Box>
  );
};

export default Card;
