import { Button, Flex, Link } from '@chakra-ui/react';
import { FC, useContext } from 'react';
import UserContext from 'context/user';
import { ActionStatus } from './JoinThePoolAction';

interface FixedButtonProps {
  availableAction: ActionStatus;
  isShown: boolean;
}

const FixedButton: FC<FixedButtonProps> = ({ availableAction, isShown }) => {
  const { login } = useContext(UserContext);

  const renderButton = () => {
    switch (availableAction) {
      case ActionStatus.CONNECT_WALLET:
        return (
          <Button
            height="44px"
            p="12px 20px"
            bg="primary"
            color="white"
            border="none"
            borderRadius="50px"
            onClick={login}
          >
            Connect Wallet
          </Button>
        );

      case ActionStatus.WAIT_FOR_STAKING:
        return (
          <Link
            href="#join-the-pool"
            height="44px"
            p="12px 20px"
            bg="primary"
            color="white"
            borderRadius="50px"
            fontWeight="600"
          >
            Go to Stake
          </Link>
        );

      case ActionStatus.VOTE:
        return (
          <Link
            href="#join-the-pool"
            minWidth="120px"
            height="44px"
            p="12px 20px"
            bg="primary"
            color="white"
            borderRadius="50px"
            fontWeight="600"
            textAlign="center"
          >
            Vote
          </Link>
        );

      default:
        return null;
    }
  };

  return (
    <Flex
      width="100%"
      justifyContent="center"
      position="fixed"
      left="0"
      bottom="20px"
      zIndex="1"
      transform={isShown ? 'translateY(0)' : 'translateY(100px)'}
      transition="0.3s transform"
    >
      {renderButton()}
    </Flex>
  );
};

export default FixedButton;
